/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it
import { GatsbyBrowser } from 'gatsby'

// declare global {
//   interface Window {
//     A2AFntDfnt: () => void
//   }
// }

/**
 * Logs when the client route changes
 */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = (
  args,
  options
) => {
  const { location, prevLocation } = args
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

/**
 * Called when the Gatsby browser runtime first starts.
 * eg. Google Analytics
 */
export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // if (window) {
  //   const a2aFntDfnt = new window.A2AFntDfnt()
  // }
}

/**
 * Eg. Wraps every page in a component
 */
// export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (args) => {
//   const { element, props } = args
//   // return <Layout {...props}>{element}</Layout>
// }
