import './src/style.scss'

export const onRouteUpdate = ({ location, prevLocation }) => {
  const pathname = location.pathname

  let pageTypeGA4

  switch (pathname) {
    case '/':
      pageTypeGA4 = 'homepage'
      break
    case '/404/':
    case '/404':
      pageTypeGA4 = '404'
      break
    case '/configuratore/':
    case '/configuratore':
      pageTypeGA4 = 'configuratore'
      break
    default:
      const firstPage = pathname.split('/').find((x) => x) || 'other'
      if (firstPage.includes('assistenza')) {
        pageTypeGA4 = 'assistenza'
      } else if (firstPage.includes('mobilita')) {
        pageTypeGA4 = 'mobilita'
      } else if (firstPage.includes('comuni-e-pa')) {
        pageTypeGA4 = 'comuni-e-pa'
      } else {
        pageTypeGA4 = firstPage
      }
      break
  }

  let sezioneGA4 = 'www'
  if (pageTypeGA4 === 'assistenza') {
    sezioneGA4 = 'assistenza'
  } else if (pageTypeGA4 === 'mobilita') {
    sezioneGA4 = 'mobilita'
  } else if (pageTypeGA4 === 'comuni-e-pa') {
    sezioneGA4 = 'comuni-e-pa'
  }

  const homePagesUri = ['/assistenza', '/mobilita-sostenibile', '/comuni-e-pa']
  if (homePagesUri.includes(pathname.replace(/\/*$/g, ''))) {
    pageTypeGA4 = 'homepage'
  }

  const ga4S08Cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('ga4S08='))
    ?.split('=')[1]

  const ga4S08 = JSON.parse(decodeURIComponent(ga4S08Cookie || '') || '{}')

  window?.dataLayer?.push({
    event: 'virtual_page_view',
    pageName: `a2a.it${location.pathname}`,
    tipologia_fornitura: ga4S08?.tipologia_fornitura || '',
    forniture_attive: ga4S08?.forniture_attive || '',
    crm_id: ga4S08?.crm_id || '',
    login_status: ga4S08?.login_status || 'not_logged',
    mercato: ga4S08?.mercato || '',
    modalita_login: ga4S08?.modalita_login || '',
    area: 'area_pubblica',
    stream: 'web',
    categoria: ga4S08?.categoria || '',
    tipologia_utente: ga4S08?.tipologia_utente || '',
    offerta: '',
    order_id: '',
    sezione: sezioneGA4,
    categoria_articolo: '',
    posizionamento_articolo: '',
    nome_articolo: '',
    pageType: pageTypeGA4,
    vas: '',
  })
}
