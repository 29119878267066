/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it

/**
 * Logs when the client route changes
 *
 * @type {import('gatsby').GatsbyBrowser['onRouteUpdate']}
 */
exports.onRouteUpdate = (args, options) => {
  const { location, prevLocation } = args
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

/**
 * Called when the Gatsby browser runtime first starts.
 * eg. Google Analytics
 *
 * @type {import('gatsby').GatsbyBrowser['onClientEntry']}
 */
exports.onClientEntry = () => {
  // console.log("We've started!")
  // callAnalyticsAPI()
}

/**
 * Eg. Wraps every page in a component
 *
 * @type {import('gatsby').GatsbyBrowser['wrapPageElement']}
 */
exports.wrapPageElement = (args) => {
  const { element, props } = args
  // return <Layout {...props}>{element}</Layout>
}
