/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it

/**
 * Called when changing location is started.
 *
 * @type {import('gatsby').GatsbyBrowser['onPreRouteUpdate']}
 */
exports.onPreRouteUpdate = (args, options) => {
  const { location, prevLocation } = args
  // console.log("Gatsby started to change location to", location.pathname)
  // console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
}

/**
 * Logs when the client route changes
 *
 * @type {import('gatsby').GatsbyBrowser['onRouteUpdate']}
 */
exports.onRouteUpdate = (args, options) => {
  const { location, prevLocation } = args
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

/**
 * Called when the Gatsby browser runtime first starts.
 * eg. Google Analytics
 *
 * @type {import('gatsby').GatsbyBrowser['onClientEntry']}
 */
exports.onClientEntry = (args, options) => {
  // console.log("We've started!")
  // callAnalyticsAPI()
  if (typeof window !== 'undefined') {
    window.a2aEnableLog = function (value) {
      if (value === true) {
        window.localStorage.setItem('a2a_debug_enabled', 'true')
        console.log(
          `%cDebug mode: ENABLED`,
          'background: #4fff95; color: #333; padding: 1px 6px; border-radius: 3px; font-weight: bold; font-size: 9px;'
        )
        return
      }

      window.localStorage.removeItem('a2a_debug_enabled')
      console.log(
        `%cDebug mode: DISABLED`,
        'background: #ff674f; color: #333; padding: 1px 6px; border-radius: 3px; font-weight: bold; font-size: 9px;'
      )
    }

    window.a2aDebugComponents = function (value) {
      if (value === true) {
        window.localStorage.setItem('a2a_debug_components', 'true')
        console.log(
          `%cDebug Components: ENABLED`,
          'background: #4fff95; color: #333; padding: 1px 6px; border-radius: 3px; font-weight: bold; font-size: 9px;'
        )
        return
      }

      window.localStorage.removeItem('a2a_debug_components')
      console.log(
        `%cDebug Components: DISABLED`,
        'background: #ff674f; color: #333; padding: 1px 6px; border-radius: 3px; font-weight: bold; font-size: 9px;'
      )
    }
  }
}

/**
 * Eg. Wraps every page in a component
 *
 * @type {import('gatsby').GatsbyBrowser['wrapPageElement']}
 */
exports.wrapPageElement = (args) => {
  const { element, props } = args
  // return <Layout {...props}>{element}</Layout>
}
